export class RizeDataModel {

    public DATA_SID: number;
    public REPORTING_PERIOD_SID: number;
    public REPORTING_QUARTER: string;
    public M1_DEN: string;
    public M1_NUM: number;
    public M2_DEN: number;
    public M2_NUM_PART_A: number;
    public M2_NUM_PART_B: number;
    public M3_DEN: number;
    public M3_NUM_PART_A: number;
    public M3_NUM_PART_B: number;
    public M4_DEN: number;
    public M4_NUM_PART_A: number;
    public M4_NUM_PART_B: number;
    public M5_DEN: number;
    public M5_NUM: number;
    public SF_MEMBERSHIP_SID: string;
    public SALESFORCE_ORG_SID: string;
    public SALESFORCE_USER_SID: string;
    public SUBMITTER_EMAIL_ID: string;
    public ORGANIZATION_NAME: string;
    public RECORD_CREATE_DT: string;
    public RECORD_UPDATE_DT: string;
    public SUBMISSION_BATCH_ID: string;
    public SUBMISSION_SOURCE: string;
  
    constructor() {

    }
  }